import React, {useEffect, useState} from 'react';
import './App.css';
import dollorImage from './assets/images/dollor.png'

function App() {

    /* 결과 */
    const [DayCost, setDayCost] = useState(0);                      // 일간 비용
    const [WeekCost, setWeekCost] = useState(0);                    // 주간 비용
    const [MonthCost, setMonthCost] = useState(0);                  // 월간 비용
    const [YearCost, setYearCost] = useState(0);                    // 연간 비용

    /* 입력받는 값 */
    const [VisitCount, setVisitCount] = useState(0);                        // 하루 방문자 수
    const [WcVdoCount, setWcVdoCount] = useState(0);                        // 1명당 하루 영상 시청 횟수
    const [OneVdoSize, setOneVdoSize] = useState(0);                        // 하나 영상 평균 크기
    const [UldVdoCount, setUldVdoCount] = useState(0);                      // 업로드 된 영상 개수

    useEffect(() => {

        let dayCost = 0;
        let weekCost = 0;
        let monthCost = 0;
        let yearCost = 0;

        /* 스토리지 저장소 비용 */
        dayCost += OneVdoSize/1024*UldVdoCount*0.0025;
        weekCost += OneVdoSize/1024*UldVdoCount*0.0025;
        monthCost += OneVdoSize/1024*UldVdoCount*0.0025;
        yearCost += OneVdoSize/1024*UldVdoCount*0.0025;

        console.log('스토리지 저장소 비용: ' + OneVdoSize/1024*UldVdoCount*0.0025);
        console.log('영상 업로드 비용: ' + UldVdoCount/1000*0.0045);
        console.log('하루 영상 다운로드 수 비용: ' + (VisitCount*WcVdoCount)/1000*0.00035);
        console.log('하루 영상 다운로드 요청 비용: ' + VisitCount*WcVdoCount*(OneVdoSize/1024)*0.126);

        /* 영상 다운로드 수 비용 */
        dayCost += (VisitCount*WcVdoCount)*0.00035/1000;
        weekCost += (VisitCount*WcVdoCount*7)*0.00035/1000;
        monthCost += (VisitCount*WcVdoCount*30)*0.00035/1000;
        yearCost += (VisitCount*WcVdoCount*365)*0.00035/1000;

        /* 영상 다운로드 요청 비용 */
        dayCost += VisitCount*WcVdoCount*(OneVdoSize/1024)*0.126;
        weekCost += VisitCount*WcVdoCount*(OneVdoSize/1024)*7*0.126;
        monthCost += VisitCount*WcVdoCount*(OneVdoSize/1024)*30*0.126;
        yearCost += VisitCount*WcVdoCount*(OneVdoSize/1024)*365*0.126;

        /* 영상 업로드 비용 */
        dayCost += UldVdoCount/1000*0.0045;
        weekCost += UldVdoCount/1000*0.0045;
        monthCost += UldVdoCount/1000*0.0045;
        yearCost += UldVdoCount/1000*0.0045;

        setDayCost(dayCost);
        setWeekCost(weekCost);
        setMonthCost(monthCost);
        setYearCost(yearCost);
    }, [OneVdoSize, UldVdoCount, VisitCount, WcVdoCount]);

    return (
        <>
            <div className="App">
                <div className="Header">
                    <div className="Header-Title">S3 Cost Calculator</div>
                    <div className="Header-Sub-Title">Let me help you calculate your AWS S3 cost</div>
                </div>
                <div className="Contents-Container">
                    <div className="Contents">
                        <div className="Calculator-Contents">

                            <div className="Calculator-Values">
                                <div className="Calculator-Text">How many visitors do you have per day on your site?</div>
                                <div className="Calculator-Inputs">
                                    <input type="range" max="1000" value={VisitCount} onChange={el => {
                                        let num : any = el.target.value;
                                        setVisitCount(num);
                                    }} />
                                    <input type="number" value={VisitCount} onChange={el => {
                                        let num : any = el.target.value;
                                        setVisitCount(num);
                                    }} />
                                </div>
                                <div className="Calculator-Text">How many videos do visitors watch a day?</div>
                                <div className="Calculator-Inputs">
                                    <input type="range" value={WcVdoCount} onChange={el => {
                                        let num : any = el.target.value;
                                        setWcVdoCount(num);
                                    }} />
                                    <input type="number" value={WcVdoCount} onChange={el => {
                                        let num : any = el.target.value;
                                        setWcVdoCount(num);
                                    }} />
                                </div>
                                <div className="Calculator-Text">What is the average size of the video? (MBs)</div>
                                <div className="Calculator-Inputs">
                                    <input type="range" max="2048" value={OneVdoSize} onChange={el => {
                                        let num : any = el.target.value;
                                        setOneVdoSize(num);
                                    }} />
                                    <input type="number" value={OneVdoSize} onChange={el => {
                                        let num : any = el.target.value;
                                        setOneVdoSize(num);
                                    }} />
                                </div>
                                <div className="Calculator-Text">How many videos have you uploaded?</div>
                                <div className="Calculator-Inputs">
                                    <input type="range" max="1000" value={UldVdoCount} onChange={el => {
                                        let num : any = el.target.value;
                                        setUldVdoCount(num);
                                    }} />
                                    <input type="number" value={UldVdoCount} onChange={el => {
                                        let num : any = el.target.value;
                                        setUldVdoCount(num);
                                    }} />
                                </div>
                            </div>

                        </div>
                        <div className="Result-Contents">
                            <div className="Result-Title">S3 Storage Cost</div>
                            <div className="Result-Value"><span>${DayCost.toFixed(2)}</span>&nbsp; / day</div>
                            <div className="Result-Value"><span>${WeekCost.toFixed(2)}</span>&nbsp;/ week</div>
                            <div className="Result-Value"><span>${MonthCost.toFixed(2)}</span>&nbsp; / month</div>
                            <div className="Result-Value"><span>${YearCost.toFixed(2)}</span>&nbsp;/ year</div>
                            <img className="Result-Image" src={dollorImage}/>
                        </div>
                    </div>
                </div>
                <div className="Footer">Copyright 2022. FUNIDEA All rights reserved.</div>
            </div>
            <ins className="adsbygoogle"
                 style={{display: 'block'}}
                 data-ad-client="ca-pub-6163050877922629"
                 data-ad-slot="2465804595"
                 data-ad-format="auto"
                 data-full-width-responsive="true"></ins>
        </>

    );
}

export default App;
